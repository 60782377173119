const navLinks = [
  {
    id: "products",
    title: "Products",
  },
  {
    id: "solutions",
    title: "Solutions",
  },
  {
    id: "pricing",
    title: "Pricing",
  },
  {
    id: "developers",
    title: "Developers",
  },
  {
    id: "resources",
    title: "Resources",
  },
  {
    id: "about",
    title: "About",
  },
];

export default navLinks;
