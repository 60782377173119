import { styles } from "../styles";

const SectionWrapper = (Component, idName) =>
  function HOC(props) {
    return (
      <section
        className={`${styles.padding} mx-auto relative z-0 overflow-hidden h-[${
          props.height ? props.height : "100vh"
        }] slides`}
      >
        <span className="hash-span" id={idName}>
          &nbsp;
        </span>
        <Component {...props} />
      </section>
    );
  };

export default SectionWrapper;
