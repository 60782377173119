import { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { getCurrentUser, signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { getApiKey } from "../graphql/queries";
import { createApiKey, updateApiKey } from "../graphql/mutations";

import { NavigationBarLogIn, Button } from "../components";
import { styles } from "../styles";

const AssetsManagement = () => {
  const navigate = useNavigate();
  const client = generateClient();
  const [user, setUser] = useState(null);
  const [apiKey, setApiKey] = useState({});

  // handle sign out
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/signin");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  // check if user is login or not
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await getCurrentUser();
        return response;
      } catch (error) {
        return false;
      }
    };

    checkAuthStatus().then((res) => {
      if (!res) {
        navigate("/signin");
      } else {
        setUser(res);
      }
    });
  }, [navigate]);

  return (
    <div className="relative z-0 bg-purple scroll-block">
      <NavigationBarLogIn handleSignOut={handleSignOut} />
      Assets Management
    </div>
  );
};

export default AssetsManagement;
