import { Link } from "react-router-dom";

import Button from "./Button";

import { styles } from "../styles";

import { unity_logo, unreal_logo, hero_image } from "../assets";

const Hero = () => {
  return (
    <div className="relative overflow-hidden">
      <main>
        <div className="pt-10 sm:pt-16 lg:pt-8 lg:pb-0 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div>
                  <h1 className="mt-4 sm:mt-5 lg:mt-6 tracking-tight">
                    <span
                      className={`${styles.heroHeadText} block text-3xl sm:text-4xl xl:text-5xl text-white`}
                    >
                      InfiniteScene AI
                    </span>
                    <div className="inline-flex">
                      <span
                        className={`${styles.heroHeadText} block text-3xl sm:text-4xl xl:text-5xl pb-2 pr-[0.82rem]`}
                      >
                        Revolutionize Your Game Development Experience
                      </span>
                    </div>
                  </h1>
                  <p
                    className={`${styles.heroSubText} mt-3 text-base text-white sm:mt-5 sm:text-xl lg:text-lg xl:text-xl`}
                  >
                    Create immersive 3D scenes with simple prompts
                  </p>
                  <div className="mt-10 sm:mt-12">
                    <div className="sm:max-w-xl sm:mx-auto lg:mx-0">
                      <div className="sm:flex">
                        <Link className="min-w-0 flex-1" to="/signin">
                          <Button
                            primary
                            rounded
                            className="mb-5 sm:mb-0 block w-full py-3.5 px-4 shadow text-white font-medium flex justify-center"
                          >
                            Get Your API Key
                          </Button>
                        </Link>
                        <a className="mt-3 sm:mt-0 sm:ml-5" href="/">
                          <Button
                            rounded
                            className="block w-full py-[0.8rem] px-4 shadow bg-transparent hover:bg-dark-blue text-white"
                          >
                            Contact us
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p
                    className={`${styles.heroSubText} mt-3 text-base text-white sm:mt-5 sm:text-xl lg:text-lg xl:text-xl`}
                  >
                    Already have a key?{" "}
                    <a href="/">
                      <span className="underline">Click Here</span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="relative pt-8 hidden lg:block">
                <div>
                  <img src={hero_image} alt="hero"></img>
                </div>
                <div className="absolute bottom-0 right-6 sm:right-12 sm:bottom-12 w-56 text-right hidden lg:block">
                  <div className="relative inline-block text-left">
                    <div className="flex">
                      <img
                        src={unity_logo}
                        className="-ml-2 -mr-2 h-30 w-60"
                        alt="unity logo"
                      ></img>
                      <img
                        src={unreal_logo}
                        className="-ml-2 -mr-2 h-20 w-20"
                        alt="unity logo"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Hero;
