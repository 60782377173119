import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { signUp, getCurrentUser } from "aws-amplify/auth";

import {
  email_validation,
  first_name_validation,
  last_name_validation,
  password_validation,
  confirm_password_validation,
} from "../utils/inputValidations";

import { Button, Input } from "../components";

import { infinitesceneai_logo } from "../assets";

const SignUpPage = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const methods = useForm();
  const navigate = useNavigate();

  const handleFormSubmit = methods.handleSubmit(async (data) => {
    try {
      if (data.password !== data.confirmPassword) {
        throw new Error("Password where not match!");
      }
      const { isSignUpComplete, nextStep } = await signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          given_name: data.firstName,
          family_name: data.lastName,
        },
      });

      if (!isSignUpComplete && nextStep.signUpStep === "CONFIRM_SIGN_UP") {
        navigate(`/confirm/${encodeURIComponent(data.email)}`);
      } else {
        navigate("/key");
      }
      setIsInvalid(false);
      methods.reset();
    } catch (error) {
      setIsInvalid(true);
      setErrorMessage(error.message);
    }
  });

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await getCurrentUser();
        return true;
      } catch (error) {
        return false;
      }
    };

    checkAuthStatus().then((res) => {
      if (res) {
        navigate("/key");
      }
    });
  }, [navigate]);

  return (
    <div className="bg-purple flex flex-col justify-center h-[100vh]">
      <div className="relative z-10 flex flex-1 flex-col p-6 justify-center my-0 sm:m-6 sm:rounded-lg">
        <div className="mx-auto w-full max-w-md sm:px-4 md:w-{22rem} md:max-w-sm md:px-0">
          <div className="flex flex-col">
            <div>
              <img
                aria-hidden="true"
                alt="infinitescene ai logo"
                src={infinitesceneai_logo}
                className="h-20 w-auto mx-auto"
              />
            </div>
            <div className="mt-3 mx-auto">
              <h2 className="text-lg text-white">Sign Up</h2>
            </div>
          </div>
          <FormProvider {...methods}>
            <form
              className="mt-4 grid grid-cols-1 gap-4 sm:gap-3 sm:grid-cols-2"
              onSubmit={handleFormSubmit}
            >
              <div className="flex flex-col">
                <Input {...first_name_validation} />
              </div>

              <div className="flex flex-col">
                <Input {...last_name_validation} />
              </div>

              <div className="col-span-full">
                <Input {...email_validation} />
              </div>

              <div className="col-span-full relative">
                <Input {...password_validation} />
              </div>

              <div className="col-span-full relative">
                <Input {...confirm_password_validation} />
              </div>
              {isInvalid && (
                <span className="text-rose-500 ml-2 mt-2 col-span-full">
                  {errorMessage}
                </span>
              )}

              <Button
                handleOnClick={handleFormSubmit}
                primary
                rounded
                type="submit"
                className="mt-4 w-full flex justify-center col-span-full"
              >
                Submit
              </Button>
            </form>
          </FormProvider>
          <div className="flex flex-col">
            <p className="mx-auto mt-5 text-xs text-white">
              Already have an account?{" "}
              <Link className="text-pink" to="/signin">
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
