const styles = {
  paddingX: "sm:px-16 px-6",
  paddingY: "sm:py-16 py-6",
  padding: "sm:px-16 px-6 sm:py-16 py-10",

  heroHeadText:
    "font-serif text-dark-gray lg:text-[50px] sm:text-[50px] xs:text-[40px] text-[40px] lg:leading-[78px] mt-2 font-sans",
  heroSubText: "text-light-gray font-small font-sans",

  sectionHeadText:
    "text-dark-gray font-serif md:text-[35px] sm:text-[25px] xs:text-[15px] text-[5px] uppercase",
  sectionSubText:
    "sm:text-[18px] text-[14px] text-light-gray tracking-wider font-sans",
};

export { styles };
