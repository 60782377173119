import { SectionWrapper } from "../hoc";

import { infinitesceneai_logo } from "../assets";

import { medias } from "../constants";

const Footer = () => {
  return (
    <footer className="text-sm leading-6 h-full">
      <div className="max-w-7xl mx-auto divide-y divide-slate-200 px-4 sm:px-6 md:px-8 dark:divide-slate-700">
        <div className="flex">
          <div className="flex-none w-1/2 space-y-10 sm:space-y-8 lg:flex lg:space-y-0">
            <div className="lg:flex-none lg:w-1/2">
              <h2 className="font-semibold text-slate-900 dark:text-slate-100">
                Community
              </h2>
              <ul className="mt-3 space-y-2">
                {medias.map((val) => (
                  <li key={val.id}>
                    <a
                      className="text-light-gray hover:text-slate-900 dark:hover:text-slate-300"
                      href={val.link}
                    >
                      {val.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-16 pt-10">
          <img
            src={infinitesceneai_logo}
            alt="infinitescene ai logo"
            viewBox="0 0 248 31"
            className="text-slate-900 dark:text-white w-auto h-24"
          />
        </div>
      </div>
    </footer>
  );
};

export default SectionWrapper(Footer, "footer");
