import { NavigationBar, Hero, SectionContainer, Footer } from "../components";

import { demo_image, unity_logo } from "../assets";

const HomePage = () => {
  const images = [demo_image, unity_logo, demo_image];
  return (
    <div className="relative z-0 bg-purple scroll-block">
      <NavigationBar />
      <div className="h-[100vh] flex justify-center items-center slides">
        <Hero />
      </div>
      <SectionContainer
        side={"left"}
        title={"How it work?"}
        description={
          "InfiniteScene AI helps to execute your imagination. Every generated asset can be further tuned and adjusted."
        }
        images={images}
      />
      <SectionContainer
        side={"right"}
        title={"Used to taking forever to match assets? Solved."}
        description={
          "We generate scenes matching your game design. Either that’s realistic, cartoon, or low poly!"
        }
        images={images}
      />
      <Footer height={"100%"} />
    </div>
  );
};

export default HomePage;
