import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { signIn, getCurrentUser, resendSignUpCode } from "aws-amplify/auth";

import {
  email_validation,
  password_validation,
} from "../utils/inputValidations";

import { Button, Input } from "../components";

import { infinitesceneai_logo } from "../assets";

import { styles } from "../styles";

const SignInPage = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const methods = useForm();
  const navigate = useNavigate();

  const handleFormSubmit = methods.handleSubmit(async (data) => {
    try {
      setIsInvalid(false);
      const { isSignedIn, nextStep } = await signIn({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
        },
      });

      if (!isSignedIn && nextStep.signInStep === "CONFIRM_SIGN_UP") {
        await resendSignUpCode({ username: data.email });
        navigate(`/confirm/${encodeURIComponent(data.email)}`);
      } else {
        navigate("/key");
      }
      methods.reset();
    } catch (error) {
      setIsInvalid(true);
      if (error.name === "UserNotFoundException") {
        setErrorMessage(
          `${error.message} Please check your email and password`
        );
      } else {
        setErrorMessage(error.message);
      }
    }
  });

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await getCurrentUser();
        return true;
      } catch (error) {
        return false;
      }
    };

    checkAuthStatus().then((res) => {
      if (res) {
        navigate("/key");
      }
    });
  }, [navigate]);

  return (
    <div className="bg-purple flex flex-col justify-center h-[100vh]">
      <div className="flex flex-col items-center">
        <img
          src={infinitesceneai_logo}
          alt="infinitescene ai logo"
          className="h-20 w-auto"
        />
        <span className={`${styles.sectionHeadText} normal-case`}>Sign In</span>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={handleFormSubmit}
          className="max-w-sm mx-auto w-full"
          noValidate
        >
          <div className="mb-5">
            <Input {...email_validation} />
          </div>
          <div className="mb-5">
            <Input {...password_validation} />
          </div>
          <div className="flex items-start mb-5">
            <div className="flex items-center h-5">
              <input
                id="remember"
                type="checkbox"
                value=""
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                required
              />
            </div>
            <label
              htmlFor="remember"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Remember me
            </label>
          </div>
          {isInvalid && (
            <span className="text-rose-500 ml-2 mt-2 col-span-full max-w-sm mx-auto w-full">
              {errorMessage}
            </span>
          )}
          <Button
            primary
            rounded
            type="submit"
            className="mt-4 w-full flex justify-center"
            onClick={handleFormSubmit}
          >
            Submit
          </Button>
        </form>
      </FormProvider>
      <div className="flex flex-col items-center mt-4">
        <span className="text-white">
          Don't have an account?{" "}
          <Link to="/signup" className="text-pink">
            Sign Up
          </Link>
        </span>
        <span className="text-pink cursor-pointer">Forgot Password?</span>
      </div>
    </div>
  );
};

export default SignInPage;
