export const first_name_validation = {
  name: "firstName",
  label: "First Name",
  type: "text",
  id: "firstName",
  placeholder: "Write your first name ...",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    maxLength: {
      value: 30,
      message: "30 characters max",
    },
  },
};

export const last_name_validation = {
  name: "lastName",
  label: "Last Name",
  type: "text",
  id: "lastName",
  placeholder: "Write your last name ...",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    maxLength: {
      value: 30,
      message: "30 characters max",
    },
  },
};

export const password_validation = {
  name: "password",
  label: "Password",
  type: "password",
  id: "password",
  placeholder: "Password",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    minLength: {
      value: 6,
      message: "min 6 characters",
    },
  },
};

export const confirm_password_validation = {
  name: "confirmPassword",
  label: "Confirm Password",
  type: "password",
  id: "confirmPassword",
  placeholder: "Password",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    minLength: {
      value: 6,
      message: "min 6 characters",
    },
  },
};

export const num_validation = {
  name: "num",
  label: "number",
  type: "number",
  id: "num",
  placeholder: "number",
  validation: {
    required: {
      value: true,
      message: "required",
    },
  },
};

export const confirmation_code_validation = {
  name: "verificationCode",
  label: "Confirmation Code",
  type: "text", // Assuming the verification code is alphanumeric
  id: "verificationCode",
  placeholder: "Enter your code",
  validation: {
    required: {
      value: true,
      message: "Verification code is required",
    },
    minLength: {
      value: 6, // Adjust the minimum length as needed
      message: "Verification code must be at least 6 characters long",
    },
    maxLength: {
      value: 10, // Adjust the maximum length as needed
      message: "Verification code must not exceed 10 characters",
    },
    pattern: {
      value: /^[a-zA-Z0-9]+$/, // Adjust the pattern as needed
      message: "Verification code must be alphanumeric",
    },
  },
};

export const email_validation = {
  name: "email",
  label: "Email",
  type: "email",
  id: "email",
  placeholder: "example@example.com",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "Email is required!",
    },
  },
};
