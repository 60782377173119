const medias = [
  {
    id: "github",
    title: "GitHub",
    link: "/",
  },
  {
    id: "discord",
    title: "Discord",
    link: "/",
  },
  {
    id: "twitter",
    title: "Twitter",
    link: "/",
  },
  {
    id: "youtube",
    title: "YouTube",
    link: "/",
  },
];

export default medias;
