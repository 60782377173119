import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { Button } from "./index.js";

import { SectionWrapper } from "../hoc";

import { styles } from "../styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const SectionContainer = ({ title, description, side, images }) => {
  return (
    <>
      <div className="relative flex items-center lg:h-full">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:grid-flow-col-dense lg:gap-6">
          <div className="px-4 max-w-xl max-h-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-span-5">
            <div>
              <div className="mt-2">
                <h2
                  className={`${styles.sectionHeadText} font-extrabold tracking-tight text-dark-gray`}
                >
                  {title}
                </h2>
                <p className={`${styles.sectionSubText} mt-4`}>
                  {description}{" "}
                </p>
                <div className="mt-6">
                  <Link
                    // className="inline-flex bg-gradient-to-r from-blue-600 to-sky-600 hover:from-blue-500 hover:to-sky-500 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white"
                    to="/signin"
                  >
                    <Button
                      primary
                      rounded
                      className="mt-4 w-full flex justify-center"
                    >
                      Get Started
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`mt-2 sm:mt-4 lg:mt-0 lg:col-span-7 max-h-xl ${
              side === "right" ? "lg:col-start-1" : ""
            }`}
          >
            <div className="box">
              <Carousel
                useKeyboardArrows={true}
                autoPlay={true}
                infiniteLoop={true}
                interval="2000"
                showThumbs={false}
                showStatus={false}
              >
                {images.map((URL, index) => (
                  <div className="slide">
                    <img
                      className="h-full w-full"
                      alt="sample_file"
                      src={URL}
                      key={index}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
            {/* <div
              className={`${
                side === "left"
                  ? "pl-4 -mr-48 sm:pl-6 md:-mr-16"
                  : "pr-4 -ml-48 sm:pr-6 md:-ml-16"
              } lg:px-0 lg:m-0 lg:relative lg:h-full ${
                side === "left" ? "img-effect-right" : "img-effect-left"
              }`}
            >
              <img
                className={`w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute ${
                  side === "left" ? "lg:left-0" : "lg:right-0"
                } lg:h-full lg:w-auto lg:max-w-none`}
                src={image}
                alt="demo scene"
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionWrapper(SectionContainer, "sectioncontainer");
