import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "./index.js";
import { menu, close, infinitesceneai_logo } from "../assets";

import { navLinks } from "../constants";

const NavigationBarLogIn = ({ handleSignOut }) => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 1) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`fixed w-full mx-auto flex items-center justify-between px-4 sm:px-6 top-0 z-20 h-[72px] ${
        scrolled
          ? "bg-black border-solid border-b-2 border-gray-500"
          : "bg-transparent"
      }`}
      aria-label="Global"
    >
      <div className="flex items-center flex-1 space-y-1 pb-2 pt-2">
        {" "}
        <div className="flex flex-1 lg:flex-none items-center justify-between">
          {" "}
          <Link to="/">
            <img
              aria-hidden="true"
              src={infinitesceneai_logo}
              className="h-12 w-auto md:h-full lg:h-16 max-h-16 min-h-12 min-w-12"
              alt="test"
            />
          </Link>
        </div>
        {/* <div className="relative bg-transparent hidden md:block">
          <div className="flex justify-between items-center px-4 sm:px-6 md:justify-start md:space-x-10">
            <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
              <nav className="flex space-x-2 lg:space-x-8 items-center">
                {" "}
                {navLinks.map((nav) => (
                  <div key={nav.id} className="relative">
                    <div>
                      <Button navigation type="button" aria-expanded="false">
                        <span>{nav.title}</span>
                      </Button>
                    </div>
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div> */}
      </div>
      <div className="flex items-center space-x-6">
        <Button
          onClick={handleSignOut}
          rounded
          className="text-base text-white hover:text-dark-gray"
        >
          Log&nbsp;Out
        </Button>

        <div className="md:hidden flex flex-1 justify-end items-center relative">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 bg-black absolute top-10 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col gap-4">
              {navLinks.map((nav) => (
                <li
                  key={nav.id}
                  className={`cursor-pointer text-[16px] ${
                    active === nav.title ? "text-green" : "text-white"
                  }`}
                  onClick={() => {
                    setToggle(!toggle);
                    setActive(nav.title);
                  }}
                >
                  <a href={`#${nav.id}`}>{nav.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBarLogIn;
