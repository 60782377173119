import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";

import "./App.css";
import {
  HomePage,
  SignInPage,
  SignUpPage,
  ConfirmSignUpPage,
  ApiKeyPage,
  AssetsManagement,
} from "./pages";
Amplify.configure(awsconfig);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/confirm/:email" element={<ConfirmSignUpPage />} />
        <Route path="/key" element={<ApiKeyPage />} />
        <Route path="/assets-management" element={<AssetsManagement />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
