/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createApiKey = /* GraphQL */ `
  mutation CreateApiKey(
    $input: CreateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    createApiKey(input: $input, condition: $condition) {
      id
      APIKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateApiKey = /* GraphQL */ `
  mutation UpdateApiKey(
    $input: UpdateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    updateApiKey(input: $input, condition: $condition) {
      id
      APIKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteApiKey = /* GraphQL */ `
  mutation DeleteApiKey(
    $input: DeleteApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    deleteApiKey(input: $input, condition: $condition) {
      id
      APIKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
