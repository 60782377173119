import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import {
  getCurrentUser,
  confirmSignUp,
  resendSignUpCode,
} from "aws-amplify/auth";

import { confirmation_code_validation } from "../utils/inputValidations";

import { Button, Input } from "../components";

import { infinitesceneai_logo } from "../assets";

import { styles } from "../styles";

const ConfirmSignUpPage = () => {
  const [confirming, setConfirming] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const methods = useForm();
  const navigate = useNavigate();
  const { email } = useParams();
  const decodedEmail = decodeURIComponent(email);

  const handleFormSubmit = methods.handleSubmit(async (data) => {
    try {
      setIsInvalid(false);
      setConfirming(true);
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username: decodedEmail,
        confirmationCode: data.verificationCode,
        attributes: {
          email: decodedEmail, // Use email as an attribute
        },
      });

      if (isSignUpComplete && nextStep.signUpStep === "DONE") {
        navigate("/signin");
      }
      methods.reset();
    } catch (error) {
      setIsInvalid(true);
      setErrorMessage(error.message);
    } finally {
      setConfirming(false);
    }
  });

  const handleResendCode = async (data) => {
    try {
      setIsInvalid(false);
      await resendSignUpCode({ username: decodedEmail });
      // Handle success (show a success message or update UI)
    } catch (error) {
      // Handle error (show an error message or update UI)
      setIsInvalid(true);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    // Check authentication status and redirect if authenticated
    const checkAuthStatus = async () => {
      try {
        // const user = await getCurrentUser();
        await getCurrentUser();
        return true;
      } catch (error) {
        return false;
      }
    };

    checkAuthStatus().then((res) => {
      if (res) {
        navigate("/key");
      }
    });
  }, [navigate]); // Add navigate to the dependencies array to avoid React lint warning

  return (
    <div className="bg-purple flex flex-col justify-center h-[100vh]">
      <div className="flex flex-col items-center">
        <img
          src={infinitesceneai_logo}
          alt="infinitescene ai logo"
          className="h-20 w-auto"
        />
        <span className={`${styles.sectionHeadText} normal-case`}>
          Confirmation
        </span>
      </div>
      <div></div>
      <FormProvider {...methods}>
        <form
          onSubmit={handleFormSubmit}
          className="max-w-sm mx-auto w-full"
          noValidate
        >
          <p className="text-light-gray mt-6 mb-6">
            Your code is on the way. To log in, enter the code we send to your
            email. It may take a minute to arrive. Please check your spam folder
            if you didn't find it.
          </p>
          <div className="mb-5">
            <Input {...confirmation_code_validation} />
          </div>
          {isInvalid && (
            <span className="text-rose-500 mb-5">{errorMessage}</span>
          )}
          <Button
            primary
            rounded
            type="submit"
            className="mt-4 w-full flex justify-center"
            onClick={handleFormSubmit}
            disabled={confirming}
          >
            {confirming ? "Confirming..." : "Submit"}
          </Button>
        </form>
        <Button
          rounded
          className="mt-4 w-full flex justify-center text-light-gray max-w-sm mx-auto"
          onClick={handleResendCode}
        >
          Resend Code
        </Button>
      </FormProvider>
    </div>
  );
};

export default ConfirmSignUpPage;
