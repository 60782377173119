import { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { getCurrentUser, signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { getApiKey } from "../graphql/queries";
import { createApiKey, updateApiKey } from "../graphql/mutations";
import { v4 as uuid } from "uuid";

import { NavigationBarLogIn, Button } from "../components";
import { styles } from "../styles";

const ApiKeyPage = () => {
  const navigate = useNavigate();
  const client = generateClient();
  const [user, setUser] = useState(null);
  const [apiKey, setApiKey] = useState({});

  // fetch a list of api keys
  const fetchApiKeys = async () => {
    try {
      const apiKeyResponse = await client.graphql({
        query: getApiKey,
        variables: {
          id: user.userId,
        },
      });
      const apiKeyData = apiKeyResponse.data.getApiKey;
      if (apiKeyData === null) {
        generateNewApiKey();
      } else {
        setApiKey(apiKeyData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // update or create a new api key to corresponding user id
  const generateNewApiKey = async () => {
    try {
      const newApiKey = uuid();
      if (Object.keys(apiKey).length === 0) {
        await client.graphql({
          query: createApiKey,
          variables: {
            input: {
              id: user.userId,
              APIKey: newApiKey,
            },
          },
        });
      } else {
        await client.graphql({
          query: updateApiKey,
          variables: {
            input: {
              id: user.userId,
              APIKey: newApiKey,
            },
          },
        });
      }
      fetchApiKeys();
    } catch (error) {
      console.log(error);
    }
  };

  // handle sign out
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/signin");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  // check if user is login or not
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await getCurrentUser();
        return response;
      } catch (error) {
        return false;
      }
    };

    checkAuthStatus().then((res) => {
      if (!res) {
        navigate("/signin");
      } else {
        setUser(res);
      }
    });
  }, [navigate]);

  // fetch api key
  useEffect(() => {
    if (user !== null) {
      fetchApiKeys();
    }
  }, [user]);

  return (
    <div className="relative z-0 bg-purple scroll-block">
      <NavigationBarLogIn handleSignOut={handleSignOut} />
      <div className=" lg:overflow-hidden flex justify-center flex-col p-20 h-full">
        <h2
          className={`${styles.sectionHeadText} font-extrabold tracking-tight text-dark-gray`}
        >
          API Key
        </h2>
        <p className={`${styles.sectionSubText} mt-4`}>
          Your API secret key are listed below. There will only going to be one
          API Key. Every signle time you generate a new API key the current one
          will be override by the new API key. Please do not share your Apl key
          with others, or expose it in cientside code.
        </p>
        <div class="mt-4">
          <div class="not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
            <div
              class="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]"
              style={{ backgroundPosition: "10px 10px;" }}
            ></div>
            <div class="relative rounded-xl overflow-auto">
              <div class="shadow-sm overflow-hidden my-8">
                <table class="border-collapse table-fixed w-full text-sm">
                  <thead>
                    <tr>
                      <th class="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                        Name
                      </th>
                      <th class="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                        API Key
                      </th>
                      <th class="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                        Create Date
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white dark:bg-slate-800">
                    <tr>
                      <td class="border-b border-slate-100 border-r dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                        default
                      </td>
                      <td class="border-b border-slate-100 border-r dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">
                        {apiKey.APIKey}
                      </td>
                      <td class="border-b border-slate-100 border-r dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400">
                        {apiKey.createdAt}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            primary
            rounded
            onClick={generateNewApiKey}
            className="text-white"
          >
            Generate New API Key
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApiKeyPage;
